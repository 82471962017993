import { Avatar, Button, Drawer, List, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import { formatText } from '../../lib/dateFunctions'
import { httpGetAdmin } from '../../services/http'

const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']

function formatSeconds(secs) {
    const m = Math.floor(secs / 60)
    const s = Math.round(secs % 60)
    return `${m} minutes, ${s} seconds`
}

function menu(item, logs) {
    return (
        <Timeline>
            {logs
                .sort((a, b) => new Date(a.created_on).valueOf() - new Date(b.created_on).valueOf())
                .map((l) => {
                    return (
                        <Timeline.Item key={l.id} color={l.status_code < 300 ? 'green' : 'red'}>
                            <p>method: {l.method}</p>
                            <p>path: {l.path}</p>
                            <p>Time: {l.created_on}</p>
                            <p>Process time: {l.process_time}</p>
                            <p>body: {l.request_body && JSON.stringify(l.request_body, null, 2)}</p>
                        </Timeline.Item>
                    )
                })}
        </Timeline>
    )
}

function RenderSesionFactory(showDrawer) {
    function RenderSession(item) {
        const logs = item.logs // JSON.parse(item.logs)
        const email = logs[0] ? logs[0]?.ll_user_email : ''
        return (
            <List.Item style={{ borderBottom: 'solid' }}>
                <List.Item.Meta
                    avatar={
                        <Avatar
                            style={{
                                backgroundColor: ColorList[Math.round(Math.random() * 3)],
                                verticalAlign: 'middle',
                            }}
                            size="large"
                            gap={4}
                        >
                            {email ? email.split('@')[0] : ''}
                        </Avatar>
                    }
                    title={email}
                    description={`Date: ${formatText(
                        new Date(item.session_start),
                        'MM-dd \'at\' h:mm a'
                    )} Session length: ${formatSeconds(item.session_length)}`}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        showDrawer(item, logs)
                    }}
                >
                    View {logs.length} logs
                </Button>
            </List.Item>
        )
    }
    return RenderSession
}

export default function ActivityList() {
    const [sessions, setSessions] = useState([])
    const [visible, setVisible] = useState(false)
    const [logs, setLogs] = useState([])
    const [item, setItem] = useState(null)

    const showDrawer = (item, logs) => {
        setLogs(logs)
        setItem(item)
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }

    async function fetchSessions() {
        const res = await httpGetAdmin<any>('/ll-admin/active-users')
        setSessions(res.data.data)
    }

    useEffect(() => {
        fetchSessions()
    }, [])

    return (
        <>
            <List
                pagination={{
                    defaultPageSize: 10,
                }}
                bordered
                itemLayout="horizontal"
                dataSource={sessions}
                renderItem={RenderSesionFactory(showDrawer)}
            />
            <Drawer
                width={800}
                title={logs.length > 0 && logs[0]?.ll_user_email ? logs[0]?.ll_user_email : ''}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                {menu(item, logs)}
            </Drawer>
        </>
    )
}
