import { Divider, Space, Statistic } from 'antd'
import React, { useEffect, useState } from 'react'
import AdminLayout from './AdminLayout'
import { httpGetAdmin } from '../../services/http'
import ActiveUsersGraph from './ActiveUsersGraph'
import ActivityList from './ActivityList'

export default function AdminOverview() {
    const [currentActiveUsers, setCurrentActiveUsers] = useState(0)
    const [activeUsersLastWeek, setActiveUsersLastWeek] = useState(0)
    const [activeUsersLastMonth, setActiveUsersLastMonth] = useState(0)

    async function fetchStats() {
        const resCurrent = await httpGetAdmin<any>('/ll-admin/active-users-within/1')
        setCurrentActiveUsers(resCurrent.data.data)
        const resWeek = await httpGetAdmin<any>(`/ll-admin/active-users-within/${24*7}`)
        setActiveUsersLastWeek(resWeek.data.data)
        const resMonth = await httpGetAdmin<any>(`/ll-admin/active-users-within/${24*30}`)
        setActiveUsersLastMonth(resMonth.data.data)
    }

    useEffect(() => {
        fetchStats()
    }, [])

    return (
        <AdminLayout>
            <div style={{ margin: 30 }}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Space size="large">
                        <Statistic title="Current Active Users" value={currentActiveUsers} />
                        <Statistic title="Active Users last week" value={activeUsersLastWeek} />
                        <Statistic title="Active Users last month" value={activeUsersLastMonth} />
                    </Space>
                    <Divider />
                    <h1>Daily active users:</h1>
                    <ActiveUsersGraph />
                    <Divider />
                    <ActivityList />
                </Space>
            </div>
        </AdminLayout>
    )
}
